(function () {
  $.fn.withoutInputmask = function () {
    return $(this).filter(function () {
      return !this.inputmask;
    });
  };
})();

//#region Global variables

var fieldIdsToRemoveMask = []; // This array holds the the ids of the fields that should have the masks removed before form submit. Use the removeMasksByIdOnSubmit() function.

//#endregion

//#region applyMasks.  This setups up all the default masks for the entire page

var phoneInputMask = {
  mask: "(999) 999-9999[ x9999]",
  onUnMask: function (maskedValue, unmaskedValue) {
    if (!maskedValue.match(/\d+/)) {
      return '';
    }
    if (maskedValue.match(/x\d+/)) {
      return maskedValue.replace(/_+/g, '');
    }
    else {
      return maskedValue.replace(/\sx/, '').replace(/_+/g, '');
    }
    return output;
  }
};

function applyMasks() {
  //Must use upper case AM/PM to work with isDirty
  Inputmask.extendDefinitions({
    'T': { //AM/PM
      validator: function (chrs, buffer, pos, strict, opts) {
        return new RegExp("^[a|p|A|P][m|M]").test(chrs + "M");
      },
      casing: "upper",
      cardinality: 1
    }
  });

  $('.zipMask').withoutInputmask().inputmask('99999[-9999]', {
    onUnMask: function (maskedValue, unmaskedValue) {
      var unmasked = unmaskedValue;

      if (unmaskedValue.length > 5) {
        return maskedValue.replace(/_+/, '');
      }

      return unmasked;
    }
  });

  $('.smallDecimalMask').withoutInputmask().inputmask('decimal', {
    max: 99,
    rightAlign: false
  });

  $(".phoneMask").withoutInputmask().inputmask(phoneInputMask);
  $('.yearMask').withoutInputmask().inputmask('9999');
  $(".dateMask").withoutInputmask().inputmask("mm/dd/yyyy");
  $(".timeMask").withoutInputmask().inputmask("h:s T\\M");
  $(".emailMask").withoutInputmask().inputmask("email");

  $(".integerMask").withoutInputmask().inputmask("integer", {
    rightAlign: true
  });

  $(".percentMask").withoutInputmask().inputmask("percentage", {
    autoUnmask: true,
    digits: 4,
    //The below fix is to prevent an input with a currencyMask from returning "" on .val() and form post when the value is actually "0 %". This bug was introduced in v3.2 of jquery.inputmask (https://github.com/RobinHerbots/jquery.inputmask/issues/1053).
    //The fix forces all currency inputs to always unmask (disabled by default) and then returns the masked value instead. This essentially short-circuites the internal logic of jquery.inputmask that was returning "". -STH 10/22/2015
    rightAlign: false,
    onUnMask: function (maskedValue, unmaskedValue) {
      return maskedValue;
    },
    clearMaskOnLostFocus: false
  });


  $(".optionalPercentMask").withoutInputmask().inputmask("percentage", {
    placeholder: "",
    autoUnmask: true,
    rightAlign: false,
    clearMaskOnLostFocus: false
  });

  $(".currencyMask").withoutInputmask().inputmask("currency", {
    rightAlign: false,
    min: 0,
    //The below fix is to prevent an input with a currencyMask from returning "" on .val() and form post when the value is actually "$ 0.00". This bug was introduced in v3.2 of jquery.inputmask (https://github.com/RobinHerbots/jquery.inputmask/issues/1053).
    //The fix forces all currency inputs to always unmask (disabled by default) and then returns the masked value instead. This essentially short-circuites the internal logic of jquery.inputmask that was returning "". -STH 10/22/2015
    autoUnmask: true,
    onUnMask: function (maskedValue, unmaskedValue) {
      return maskedValue;
    },
    clearMaskOnLostFocus: false
  });

  $(".negativeCurrencyMask").withoutInputmask().inputmask("currency", {
    rightAlign: false,
    prefix: '($ ',
    suffix: ')'
  });

  $(".posOrNegCurrencyMask").withoutInputmask().inputmask("currency", {
    rightAlign: false,
  });
}

//#endregion

//#region getUnmaskedNumber.  jquery.inputmask method for unmasking does not return correct value for currency.
function getUnmaskedNumber(string) {
  if (string) {
    var unmasked = string.replace(/[^0-9\.]+/g, "");
    var number = Number(unmasked);
    return number;
  }

  return 0;
}
//#endregion

//#region Remove Mask.  Call this to setup elements to remove masks for on submit

function removeMasksByIdOnSubmit() {
  $(arguments).each(function (i, e) {
    var index = fieldIdsToRemoveMask.indexOf(e);
    if (index < 0) {
      fieldIdsToRemoveMask.push(e);
    }
  });
}

function doNotRemoveMasksByIdOnSubmit() {
  $(arguments).each(function (i, e) {
    var index = fieldIdsToRemoveMask.indexOf(e);
    if (index >= 0) {
      fieldIdsToRemoveMask.splice(index, 1);
    }
  });
}

//#endregion Remove Mask